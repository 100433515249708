import './lib/rum';

import * as Sentry from '@sentry/sveltekit';

import { env as public_vars } from '$env/dynamic/public';

Sentry.addTracingExtensions();
Sentry.init({
	dsn: public_vars.PUBLIC_SENTRY_DSN,
	environment: public_vars.PUBLIC_NODE_ENV,
	release: public_vars.PUBLIC_VERSION,
	integrations: [],
	enabled: true,
	replaysSessionSampleRate: public_vars.PUBLIC_SENTRY_SAMPLE_RATE
		? parseFloat(public_vars.PUBLIC_SENTRY_SAMPLE_RATE)
		: 0.1,
	replaysOnErrorSampleRate: 0.1,
	beforeSendTransaction: (event) => {
		if (event.request?.headers?.['User-Agent'].includes('(smoke-tests)')) {
			return null;
		}
		return event;
	},
	tracesSampler: () => {
		return public_vars.PUBLIC_SENTRY_SAMPLE_RATE
			? parseFloat(public_vars.PUBLIC_SENTRY_SAMPLE_RATE)
			: 0.1;
	},
});

export const handleError = Sentry.handleErrorWithSentry();
