import SplunkOtelWeb from '@splunk/otel-web';
import SplunkSessionRecorder from '@splunk/otel-web-session-recorder';
import { io } from 'socket.io-client';

import { version } from '$app/environment';
import { env } from '$env/dynamic/public';

if (env.PUBLIC_NODE_ENV !== 'development' && env.PUBLIC_SPLUNK_RUM_TOKEN) {
	SplunkOtelWeb.init({
		realm: 'eu0',
		rumAccessToken: env.PUBLIC_SPLUNK_RUM_TOKEN,
		applicationName: 'rx-frontend',
		deploymentEnvironment: env.PUBLIC_NODE_ENV,
		instrumentations: {
			fetch: false,
			socketio: {
				target: io as any,
			},
		},
		version,
	});

	// This must be called after initializing splunk rum
	SplunkSessionRecorder.init({
		realm: 'eu0',
		rumAccessToken: env.PUBLIC_SPLUNK_RUM_TOKEN,
	});

	globalThis.splunk_rum = SplunkOtelWeb;
	globalThis.splunk_session_recorder = SplunkSessionRecorder;
}
